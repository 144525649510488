import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const SkeletonButton = styled.div`
  width: 50px;
  height: 20px;
  border-radius: 0.625rem;
  background: linear-gradient(90deg, #112a36, #0a7876, #112a36);
  background-size: 200% 200%;
  animation: ${loadingAnimation} 1.5s infinite ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimatedSkeletonButton: React.FC = () => <SkeletonButton />;

export default AnimatedSkeletonButton;
