import React from 'react';
import styled from 'styled-components';
import InfoHeader from '../components/InfoHeader';
import ReferralBox from '../components/ReferralBox';
import BottomSheet from '../components/BottomSheet';
import CustomButton from '../components/CustomButton';
import { FaCopy } from 'react-icons/fa';
import ShareButton from '../components/ShareButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 100px;
  left: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  z-index: 3000;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;

  svg {
    font-size: 18px;
  }
`;

interface ReferralProps {
  telegramUserId: string | number | null;
  paymentModalVisible: boolean;
  setPaymentModalVisible: (b: boolean) => void;
}

const Referral: React.FC<ReferralProps> = ({
  telegramUserId,
  paymentModalVisible,
  setPaymentModalVisible,
}) => {
  const botUsername = 'bitspinn_bot';
  const referralLink = telegramUserId
    ? `https://t.me/${botUsername}?start=${telegramUserId}`
    : '';

  const handleCopyLink = async () => {
    if (!referralLink) {
      toast.error('Referral link is not available.');
      return;
    }

    try {
      await navigator.clipboard.writeText(referralLink);
      toast.success('Invitation link copied!');
    } catch (err) {
      toast.error('Failed to copy the referral link. Please try again.');
    }
  };

  return (
    <div style={{ marginBottom: 80 }}>
      <InfoHeader
        paymentModalVisible={paymentModalVisible}
        setPaymentModalVisible={setPaymentModalVisible}
      />
      <ReferralBox />
      <BottomSheet />
      <ButtonContainer>
        <ShareButton
          botUsername={botUsername}
          userId={telegramUserId ? String(telegramUserId) : ''}
        />
        <CustomButton
          onClick={handleCopyLink}
          width={48}
          // @ts-ignore
          text={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 14,
                alignItems: 'center',
              }}
            >
              <IconWrapper>
                <FaCopy />
              </IconWrapper>
              Copy Link
            </div>
          }
          fontWeight="bold"
        />
      </ButtonContainer>
    </div>
  );
};

export default Referral;
