import { UseMutationOptions } from '@tanstack/react-query';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';
import { ICustomErrorResponse } from '../type';

export type GenerateChallengeResponseObject = {
  challenge: string;
};

export type GenerateChallengeRequestDto = {
  claim_id: string;
};

export type GenerateChallengeParams = Omit<
  UseMutationOptions<
    GenerateChallengeResponseObject,
    ICustomErrorResponse,
    GenerateChallengeRequestDto
  >,
  'mutationFn'
>;

export const generateChallenge = async (
  params: GenerateChallengeRequestDto
) => {
  return await mainApiInstance.post<GenerateChallengeResponseObject>(
    'claims/challenge',
    {
      body: JSON.stringify(params),
    }
  );
};

export const useGenerateChallenge = (params: GenerateChallengeParams) => {
  const { mutateAsync, isLoading } = useMutationCustom(
    ['generateChallenge'],
    generateChallenge,
    params
  );

  return { generateChallenge: mutateAsync, isLoading };
};
