import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import TitleText from './TitleText';
import { useGetReferrals } from '../services/referrals';
import { format } from 'date-fns';

const SkeletonBox = styled.div`
  background-color: #2a3449;
  border-radius: 10px;
  height: 1.5rem;
  width: 50px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      background-color: #2a3449;
    }
    50% {
      background-color: #3a4459;
    }
    100% {
      background-color: #2a3449;
    }
  }
`;

const BottomSheetContainer = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 130px);
  max-height: 90vh;
  min-height: 200px;
  background-color: #1e2034;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  z-index: 10;
`;

const ReferralList = styled.div`
  padding: 1rem;
  overflow-y: auto;
  height: calc(100% - 6rem);
  margin-bottom: env(safe-area-inset-bottom, 60px);
`;

const BottomPadding = styled.div`
  height: env(safe-area-inset-bottom, 60px);
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  z-index: 20;
`;

const ReferralItem = styled.div`
  color: #7a7b85;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 1rem;
`;
const Handle = styled.div`
  width: 40px;
  height: 6px;
  background-color: #7a7b85;
  border-radius: 3px;
  margin: 10px auto;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #7a7b85;
  margin: 10px 20px;
`;

const BottomSheet: React.FC = () => {
  const headerHeight = 340;
  const buttonHeight = 60;
  const initialPosition = window.innerHeight - headerHeight - buttonHeight;
  const [{ y }, set] = useSpring(() => ({ y: initialPosition }));

  const bind = useDrag(
    ({ last, movement: [, my], cancel }) => {
      if (my < -70) cancel();
      set({
        y: last ? (my > 0 ? initialPosition : 0) : y.get() + my,
      });
    },
    {
      from: () => [0, y.get()],
      bounds: { top: 0, bottom: initialPosition },
      rubberband: true,
    }
  );

  const { data: referrals, isLoading } = useGetReferrals();

  return (
    <BottomSheetContainer style={{ y }} {...bind()}>
      <Handle />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <TitleText
          fontWeight="700"
          title="Referral list"
          size={16}
          color="#fff"
        />
        {isLoading ? (
          <SkeletonBox />
        ) : (
          <TitleText
            title={`${
              referrals?.records?.length ? `${referrals.records.length}` : '0'
            } users`}
            size={16}
            color="#fff"
          />
        )}
      </div>
      <Separator />
      <ReferralList>
        {referrals?.records?.map((referral) => (
          <div
            key={referral.referral_id}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ReferralItem key={referral.user_id}>
              {referral.referral_username}
            </ReferralItem>
            <ReferralItem key={referral?.referral_id}>
              {format(new Date(referral.referral_date), 'MMMM dd, yyyy')}
            </ReferralItem>
          </div>
        ))}
        <BottomPadding />
      </ReferralList>
    </BottomSheetContainer>
  );
};

export default BottomSheet;
