import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CustomButton from './CustomButton';
import { useSpin } from '../services/spin';
import bitcoinIcon from '../assets/bitcoin.png';
import ethereumIcon from '../assets/ethereum.png';
import toncoinIcon from '../assets/toncoin.jpg';
import usdtIcon from '../assets/usdt.png';
import BuySpinsModal from './BuySpinModal';
import MultiplyWinModal from './MultiplyWinModal';
import { toast } from 'react-toastify';
import { useMeStore } from '../store/useMeStore';
import SlotCounter, { SlotCounterRef } from 'react-slot-counter';
import slotSpinSound from '../assets/sounds/slot-spin.mp3';
import resultSound from '../assets/sounds/result.mp3';
import confetti from 'canvas-confetti';
import winAudio from '../assets/sounds/win.mp3';
import { useQueryClient } from '@tanstack/react-query';

type SlotBoxProps = {
  isWinning?: boolean;
};

type SlotMachineProps = {
  refetchBalance: () => void;
};

const iconsMap: Record<string, string> = {
  ethereum: ethereumIcon,
  bitcoin: bitcoinIcon,
  ton: toncoinIcon,
  usdt: usdtIcon,
};

const iconValues = Object.values(iconsMap);

const generateRandomSlots = () => {
  return Array.from({ length: 3 }, () =>
    Array.from({ length: 3 }, () => {
      const randomKey =
        Object.keys(iconsMap)[
          Math.floor(Math.random() * Object.keys(iconsMap).length)
        ];
      return randomKey;
    })
  );
};

const SmallModalBackground = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 4000;
`;

const SmallModal = styled.div`
  background: rgba(42, 52, 73, 1);
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  width: 320px;
  color: white;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  // height: 90vh;
  overflow-y: auto;
`;

const SlotsContainer = styled.div`
  background-color: #15172c;
  border: 0.25rem solid #1e2034;
  border-radius: 1.875rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 450px;
  height: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    max-width: 500px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 1rem;

  button {
    margin-bottom: 1rem;
  }
`;

const SlotsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

const SlotColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SlotBox = styled.div<SlotBoxProps>`
  width: 10rem;
  height: 9rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ isWinning }) =>
    !isWinning ? '0px solid transparent' : '2px solid transparent'};
  background: ${({ isWinning }) =>
    !isWinning
      ? '#1e2034'
      : `linear-gradient(#1e2034, #1e2034) padding-box,
        linear-gradient(to bottom, #0b827f, #4a7a5d 50%, #bc6c1d) border-box`};
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 5.5rem;
    height: 4.5rem;
  }
`;

const Icon = styled.img`
  width: 3.5rem;
  height: 3.5rem;

  @media (max-width: 768px) {
    width: 2.75rem;
    height: 2.75rem;
  }
`;

const MultiplyButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultText = styled.div`
  margin: 15px 0;
  font-size: 0.7rem;
  color: #81828b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  line-height: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    height: 2rem;
  }
`;

const WonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: rgba(196, 107, 24, 1);
  text-align: center;
  margin: 10px 0;
  position: relative;
  font-weight: 700;
  height: 2.5rem;
  line-height: 1.25rem;

  &:before,
  &:after {
    content: '';
    flex: 1;
    width: 4rem;
    height: 0.125rem;
    background: linear-gradient(
      to right,
      rgba(196, 107, 24, 0) 0%,
      rgba(196, 107, 24, 1) 50%,
      rgba(196, 107, 24, 0) 100%
    );
    margin: 0 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    height: 2rem;
  }
`;

const SlotMachine: React.FC<SlotMachineProps> = ({ refetchBalance }) => {
  const [slots, setSlots] = useState<string[][]>(generateRandomSlots());
  const [winningSlots, setWinningSlots] = useState<boolean[][]>(
    Array.from({ length: 3 }, () => Array(3).fill(false))
  );
  const queryClient = useQueryClient();
  const [result, setResult] = useState<string>('');
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [showMultiplyButton, setShowMultiplyButton] = useState<boolean>(false);
  const [isBuyModalVisible, setBuyModalVisible] = useState(false);
  const [isMultiplyModalVisible, setMultiplyModalVisible] = useState(false);
  const { setAvailableSpins, setBalance, availableSpins } = useMeStore();
  const spinAudio = useRef(new Audio(slotSpinSound));
  const resultAudio = useRef(new Audio(resultSound));
  const winSound = useRef(new Audio(winAudio));
  const [isTONWinner, setIsTONWinner] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { spin } = useSpin({
    onMutate: (context) => {
      setIsSpinning(true);
      setResult('');
      setWinningSlots(Array.from({ length: 3 }, () => Array(3).fill(false)));
      setShowMultiplyButton(false);

      setSlots(() =>
        Array.from({ length: 3 }, () =>
          Array.from(
            { length: 3 },
            () => iconValues[Math.floor(Math.random() * iconValues.length)]
          )
        )
      );

      if (spinAudio.current) {
        // spinAudio.current.loop = true;
        spinAudio.current.play().catch((error) => {
          console.error('Error playing spin audio:', error);
        });
      }

      slotRefs.current.forEach((ref) => {
        if (ref) {
          // @ts-ignore
          ref.startAnimation({
            duration: 3,
            dummyCharacterCount: 10,
            speed: 1.5,
            direction: 'top-down',
          });
        }
      });

      setTimeout(() => {
        setSlots((prevSlots) =>
          prevSlots.map((column) =>
            column.map(
              () => Object.keys(iconsMap)[Math.floor(Math.random() * 4)]
            )
          )
        );
      }, 0);
      return { startTime: Date.now() };
    },
    onSuccess: (data, variables, context) => {
      const elapsedTime = Date.now() - (context?.startTime || 0);
      const remainingTime = Math.max(0, 2000 - elapsedTime);

      setTimeout(() => {
        clearInterval(context?.interval);

        const normalizeKey = (key: string): string => {
          return key.toLowerCase();
        };

        const newSlots = Array.from({ length: 3 }, (_, colIndex) =>
          data.result.map((row) => {
            const backendKey = normalizeKey(row.row[colIndex]);
            return iconsMap[backendKey] ? backendKey : 'unknown';
          })
        );

        setSlots(newSlots);

        spinAudio.current.pause();
        spinAudio.current.currentTime = 0;

        const newWinningSlots = data.result.map((row) =>
          row.isWinningRow ? [true, true, true] : [false, false, false]
        );

        setWinningSlots(newWinningSlots);
        const resultMessage =
          data.totalWin > 0 ? `You won - ${data.totalWin} BS` : 'Try Again';
        setResult(resultMessage);

        setAvailableSpins(data.spinAmount);
        setBalance(data.balance);
        setShowMultiplyButton(data.totalWin > 0);
        if (data.totalWin > 0) {
          refetchBalance();
        }
        setIsSpinning(false);

        const tonWin = data.result.some((row) =>
          row.row.every((icon) => icon === 'bitcoin')
        );

        if (tonWin) {
          setIsTONWinner(true);
          setIsConfirmModalOpen(true);
          queryClient.invalidateQueries({ queryKey: ['claims'] });
          resultAudio.current.pause();
          resultAudio.current.currentTime = 0;
          winSound.current.play();

          confetti({
            particleCount: 100,
            spread: 90,
            origin: { y: 0.6 },
          });

          setTimeout(() => {
            confetti({
              particleCount: 150,
              spread: 110,
              origin: { y: 0.6 },
            });
          }, 1000);
        } else {
          resultAudio.current.play();
        }
      }, remainingTime);
    },
    onError: (error, variables, context) => {
      clearInterval(context?.interval);
      setIsSpinning(false);
      if (spinAudio.current) {
        spinAudio.current.pause();
        spinAudio.current.currentTime = 0;
      }

      toast.error('Spin failed. Please try again.');
    },
  });

  const handleSpin = () => {
    if (availableSpins > 0 && !isSpinning) {
      setAvailableSpins(availableSpins - 1);
      spinAudio.current.play();

      spin(undefined);
    }
  };

  const buySpins = () => {
    setBuyModalVisible(true);
  };

  const multiplyWins = () => {
    setMultiplyModalVisible(true);
  };

  const slotRefs = useRef<Array<Array<SlotCounterRef | null>>>(
    Array.from({ length: slots.length }, () =>
      Array(slots[0]?.length || 0).fill(null)
    )
  );

  return (
    <>
      <CenterWrapper>
        <SlotsContainer>
          <SlotsGrid>
            {slots.map((column, colIndex) => (
              <SlotCounter
                key={`slot-counter-column-${colIndex}`}
                ref={(ref) => {
                  if (!slotRefs.current[colIndex]) {
                    slotRefs.current[colIndex] = [];
                  }
                  // @ts-ignore
                  slotRefs.current[colIndex] = ref;
                }}
                autoAnimationStart={false}
                value={[
                  <SlotColumn key={`col-${colIndex}`}>
                    {column.map((icon, rowIndex) => (
                      <SlotBox
                        key={`value-${rowIndex}-${colIndex}`}
                        isWinning={winningSlots[rowIndex]?.[colIndex]}
                      >
                        {iconsMap[icon] ? (
                          <Icon src={iconsMap[icon]} alt={icon} />
                        ) : (
                          <div>Icon Missing: {icon}</div>
                        )}
                      </SlotBox>
                    ))}
                  </SlotColumn>,
                ]}
                dummyCharacters={[
                  <SlotColumn key={`dummy-column-${colIndex}`}>
                    {Object.keys(iconsMap).map((key, dummyIndex) => (
                      <SlotBox key={`dummy-${key}-${dummyIndex}`}>
                        <Icon src={iconsMap[key]} alt={key} />
                      </SlotBox>
                    ))}
                  </SlotColumn>,
                ]}
                dummyCharacterCount={10}
                duration={3}
                speed={1.5}
                hasInfiniteList={true}
                animateUnchanged={false}
              />
            ))}
          </SlotsGrid>

          <ButtonContainer>
            {result && result !== 'Try Again' && <WonText>{result}</WonText>}
            {!result && availableSpins > 0 && (
              <ResultText>
                Click on the button &quot;{availableSpins} spin&quot; to start
                game
              </ResultText>
            )}

            {availableSpins < 1 && (
              <ResultText>You don&apos;t have enough spins</ResultText>
            )}
            {availableSpins > 0 && result === 'Try Again' && (
              <ResultText>Try Again</ResultText>
            )}
            {availableSpins > 0 ? (
              <CustomButton
                onClick={handleSpin}
                disabled={isSpinning}
                width={100}
                text={`${availableSpins} SPIN`}
                fontWeight="bold"
              />
            ) : (
              <CustomButton
                onClick={buySpins}
                disabled={isSpinning}
                width={100}
                text="Buy Spins"
                fontWeight="bold"
              />
            )}
            {/* {showMultiplyButton && availableSpins > 0 && (
              <MultiplyButtonContainer>
                <CustomButton
                  text="Multiply Win"
                  state={buttonStatus.open}
                  width={100}
                  onClick={multiplyWins}
                  disabled={isSpinning}
                  fontWeight="bold"
                />
              </MultiplyButtonContainer>
            )} */}
          </ButtonContainer>
        </SlotsContainer>
      </CenterWrapper>

      {isTONWinner && (
        <SmallModalBackground show={isConfirmModalOpen}>
          <SmallModal>
            <CloseButton onClick={() => setIsConfirmModalOpen(false)}>
              &times;
            </CloseButton>
            <h3>Hooray! 🎉</h3>
            <p>You won TON! Go to Airdrop and claim your prize.</p>
            <CustomButton
              width={100}
              text="Got it!"
              onClick={() => setIsConfirmModalOpen(false)}
            />
          </SmallModal>
        </SmallModalBackground>
      )}
      <BuySpinsModal
        show={isBuyModalVisible}
        onClose={() => setBuyModalVisible(false)}
      />
      <MultiplyWinModal
        show={isMultiplyModalVisible}
        onClose={() => setMultiplyModalVisible(false)}
      />
    </>
  );
};

export default SlotMachine;
