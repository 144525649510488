import { UseMutationOptions } from '@tanstack/react-query';
import { ICustomErrorResponse } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';

export type CreatePaymentRequestDto = {
  item_id: string;
};

export type CretePaymentResponseObject = {
  invoice_url: string;
};

export type CreatePaymentParams = Omit<
  UseMutationOptions<
    CretePaymentResponseObject,
    ICustomErrorResponse,
    CreatePaymentRequestDto
  >,
  'mutationFn'
>;

const createPayment = async (params: CreatePaymentRequestDto) => {
  return await mainApiInstance.post<CretePaymentResponseObject>(
    'payments/create-invoice',
    {
      body: JSON.stringify(params),
    }
  );
};

export const useCreatePayment = (params: CreatePaymentParams) => {
  const { mutateAsync, isLoading } = useMutationCustom(
    ['createPayment'],
    createPayment,
    params
  );

  return { createPayment: mutateAsync, isLoading };
};
