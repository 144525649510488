import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TaskStatus, buttonStatus } from '../type';

interface ButtonProps {
  text: string;
  state?: TaskStatus | null;
  width?: number;
  onClick?: () => void;
  disabled?: boolean;
  marginVertical?: number;
  fontWeight?: string;
}

const StyledButton = styled.button<{
  state?: TaskStatus | null;
  width?: number;
  marginVertical?: number;
  fontWeight?: string;
}>`
  background-color: ${({ state }) =>
    state === buttonStatus.readyToClaim
      ? '#0a7876'
      : state === buttonStatus.open
      ? '#112a36'
      : '#3a8283'};
  color: #ffffff;
  width: ${({ width }) => (width ? `${width}%` : 'auto')};
  border: 2px solid
    ${({ state }) =>
      state === buttonStatus.readyToClaim
        ? '#0a7876'
        : state === buttonStatus.open
        ? '#07797a'
        : '#fff'};
  border-radius: 0.625rem;
  border-bottom-width: ${({ state }) =>
    state === buttonStatus.open ? '2px' : '4px'};
  padding: 0.875rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: ${({ state }) =>
    state === buttonStatus.pendingApproval ? 'none' : 'inline-flex'};
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginVertical }) =>
    marginVertical ? `${marginVertical}rem` : '0'};
  margin-bottom: ${({ marginVertical }) =>
    marginVertical ? `${marginVertical}rem` : '0'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.03);
  }

  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.75rem 0.875rem;
    width: ${({ width }) => (width ? `${width}%` : 'auto')};
  }
`;

const PendingText = styled.span<{ fontWeight?: string }>`
  font-size: 16px;
  color: #ffffff;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`;

const DoneIcon = styled(CheckCircleIcon)`
  color: green;
  margin-right: 20px;
`;

const CustomButton: React.FC<ButtonProps> = ({
  text,
  state,
  width,
  onClick,
  disabled,
  marginVertical,
  fontWeight,
}) => {
  if (state === buttonStatus.pendingApproval) {
    return <PendingText fontWeight={fontWeight}>Pending...</PendingText>;
  }

  if (state === buttonStatus.completed) {
    return <DoneIcon />;
  }

  return (
    <StyledButton
      marginVertical={marginVertical}
      disabled={disabled}
      onClick={onClick}
      width={width}
      state={state}
      fontWeight={fontWeight}
    >
      {text}
    </StyledButton>
  );
};

export default CustomButton;
