import React, { useState } from 'react';
import InfoBox from '../components/InfoBox';
import styled from 'styled-components';
import { useGetUserBalance } from '../services/balance';
import { buttonStatus } from '../type';
import BuySpinsModal from './BuySpinModal';
import { useMeStore } from '../store/useMeStore';

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #1e2034;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const useUserBalanceController = () => {
  const { data, refetch } = useGetUserBalance();

  return {
    balance: data?.balance ?? '',
    refetchBalance: refetch,
  };
};

const InfoHeader = ({
  paymentModalVisible,
  setPaymentModalVisible,
}: {
  paymentModalVisible: boolean;
  setPaymentModalVisible: (b: boolean) => void;
}) => {
  const { balance } = useMeStore();

  const buySpins = () => {
    setPaymentModalVisible(true);
  };

  return (
    <>
      <StickyContainer>
        <InfoBox
          title="Tokens won in total"
          value={balance || 0}
          buttonText="Buy spins"
          buttonState={buttonStatus.open}
          onClick={buySpins}
        />
      </StickyContainer>
      <BuySpinsModal
        show={paymentModalVisible}
        onClose={() => setPaymentModalVisible(false)}
      />
    </>
  );
};

export default InfoHeader;
