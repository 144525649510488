import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% { background-position: -200% 0; }
  50% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
`;

const SkeletonContainer = styled.div`
  background: #2a3449;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  gap: 10px;
  color: #d7d7d7;
  overflow: hidden;
`;

const SkeletonBlock = styled.div`
  background: linear-gradient(90deg, #2a3449, #1e2034, #2a3449);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2.5s infinite; /* Slower animation duration */
  border-radius: 10px;
`;

const SkeletonRank = styled(SkeletonBlock)`
  width: 10%;
  height: 18px;
`;

const SkeletonName = styled(SkeletonBlock)`
  width: 65%;
  height: 18px;
`;

const SkeletonScore = styled(SkeletonBlock)`
  width: 25%;
  height: 18px;
`;

const LeaderboardSkeletonItem: React.FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonRank />
      <SkeletonName />
      <SkeletonScore />
    </SkeletonContainer>
  );
};

export default LeaderboardSkeletonItem;
