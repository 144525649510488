import styled from 'styled-components';
import TitleText from './TitleText';
import IconBox from './IconBox';

const Container = styled.div`
  background-color: #1e2034;
  display: flex;
  padding: 20px;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(#1e2034, #1e2034) padding-box,
    linear-gradient(to bottom, #173946, #2d363c 50%, #44312e) border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
`;

const ValueText = styled.span<{ color?: string }>`
  color: ${({ color }) => color || '#d7d7d7'};
  font-size: 16px;
  margin-top: 8px;
  font-weight: bold;
`;

const referralRules = [
  {
    id: 1,
    title: 'For each invited user. A inviter will get',
    value: '1000',
  },
];

const ReferralBox: React.FC = () => {
  return (
    <>
      {referralRules.map((rule) => (
        <Container key={rule.id}>
          <IconBox
            isIconBorder={false}
            iconSrc={'https://cdn-icons-png.freepik.com/512/5717/5717488.png'}
          />
          <TextContainer>
            <div>
              <ValueText>Get</ValueText>
              <ValueText color="#b6702f"> 1000 </ValueText>
              <ValueText>BS</ValueText>
            </div>
            <TitleText size={16} title={rule.title} />
          </TextContainer>
        </Container>
      ))}
    </>
  );
};

export default ReferralBox;
