import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% { background-position: -200% 0; }
  50% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 1rem;

  @media (max-width: 768px) {
    height: 70vh;
  }

  @media (max-width: 480px) {
    height: auto;
  }
`;

const SkeletonBlock = styled.div`
  background: linear-gradient(90deg, #2a3449, #1e2034, #2a3449);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2.5s infinite ease-in-out;
  border-radius: 10px;
`;

const SkeletonTitle = styled(SkeletonBlock)`
  width: 50%;
  height: 24px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: 20px;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    height: 18px;
    margin-bottom: 10px;
  }
`;

const SkeletonDescription = styled(SkeletonBlock)`
  width: 70%;
  height: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    height: 24px;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    height: 20px;
    margin-bottom: 15px;
  }
`;

const SkeletonImage = styled(SkeletonBlock)`
  width: 80%;
  height: 200px;

  @media (max-width: 768px) {
    width: 90%;
    height: 150px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 120px;
  }
`;

const AirdropSkeletonLoader: React.FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonTitle />
      <SkeletonDescription />
      <SkeletonImage />
    </SkeletonContainer>
  );
};

export default AirdropSkeletonLoader;
