import { UserModelDto } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useQueryCustom } from '../utils/useQueryCustom';

export type LeaderboardResponseObject = {
  records: UserModelDto[];
};

const getLeaderboard = async () => {
  return mainApiInstance.get<LeaderboardResponseObject>(`leaderboard`, {});
};

export const useGetLeaderboard = () => {
  return useQueryCustom<LeaderboardResponseObject>(
    ['leaderboard'],
    () => getLeaderboard(),
    {}
  );
};
