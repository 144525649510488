import { UserTask } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useQueryCustom } from '../utils/useQueryCustom';

export type TasksResponseObject = {
  records: UserTask[];
};

const getTasks = async () => {
  return await mainApiInstance.get<TasksResponseObject>(`tasks/list`, {});
};

export const useGetTasks = () => {
  return useQueryCustom<TasksResponseObject>(['tasks'], () => getTasks(), {});
};
