import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BottomNav from './navigation/BottomNav';
import Slots from './pages/Slots';
import Referral from './pages/Referral';
import Airdrops from './pages/Airdrops';
import Tasks from './pages/Tasks';
import Leaderboard from './pages/Leaderboard';
import { toast } from 'react-toastify';
import { useAuthenticateMutation } from './services/authenticate';
import { useAuthStore } from './store/useAuthStore';
import { useQueryClient } from '@tanstack/react-query';
import styled, { keyframes } from 'styled-components';
import LandingPage from './pages/LandingPage';
import { useMeStore } from './store/useMeStore';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/logo.png';

import { WebsocketPayload } from './type';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, #090d1e 0%, #102130 100%, #1a1524 20%);
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const BouncingLogo = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 50px;
  animation: ${bounce} 1.5s infinite;
`;

type UseWebhookProps = {
  userId: string;
  setAvailableSpins: (spins: number) => void;
  setBalance: (balance: number) => void;
  setPaymentModalVisible: (paymentModalVisible: boolean) => void;
};

const useWebhook = ({
  userId,
  setAvailableSpins,
  setBalance,
  setPaymentModalVisible,
}: UseWebhookProps) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}?user_id=${userId}`;
  let ws: WebSocket;
  let reconnectTimeout: NodeJS.Timeout;

  const connect = () => {
    ws = new WebSocket(url);

    ws.onopen = () => {
      console.log('WebSocket connection established');
      ws.send('Hello from React!');
    };

    ws.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data) as WebsocketPayload;
      toast.success(data.message);
      setPaymentModalVisible(false);
      setAvailableSpins(data.availableSpins);
      setBalance(data.balance);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed, attempting to reconnect...');
      reconnectTimeout = setTimeout(connect, 5000); // Attempt reconnection in 5 seconds
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      toast.error('WebSocket error');
      ws.close();
    };
  };

  connect(); // Initial connection

  return () => {
    clearTimeout(reconnectTimeout);
    ws.close();
  };
};

const App: React.FC = () => {
  const authStore = useAuthStore();
  const { setAvailableSpins, setBalance } = useMeStore();
  const queryClient = useQueryClient();
  const [telegramUserId, setTelegramUserId] = useState<string | number | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const { authenticate } = useAuthenticateMutation({
    onError: (error) => {
      console.error('Authentication Error:', error);
      toast.error(error?.error?.message || 'Authentication failed');
      setLoading(false);
    },
    onSuccess: ({ sessionToken }) => {
      authStore.setTokens({ sessionToken });
      queryClient.invalidateQueries(['balance']);
      // toast.success('Successfully authenticated');
      setLoading(false);
    },
  });

  useEffect(() => {
    const authenticateUser = async () => {
      // @ts-ignore
      if (window.Telegram.WebApp) {
        // @ts-ignore
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.setBackgroundColor('#000000');

        const search = new URLSearchParams(window.location.search);
        // @ts-ignore
        const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id || null;
        setTelegramUserId(userId);
        const result = await authenticate({
          initData: window.Telegram.WebApp.initData,
          referrer: search.get('referralCode'),
        });
        useWebhook({
          userId: String(userId),
          setAvailableSpins,
          setBalance,
          setPaymentModalVisible,
        });

        setBalance(result.balance);
        setAvailableSpins(result.spinAmount);
      } else {
        setTelegramUserId(null);
        setLoading(false);

        console.log('WebApp is not available');
      }
    };

    authenticateUser();
  }, []);

  if (loading) {
    return (
      <SpinnerContainer>
        <BouncingLogo src={logo} alt="Loading..." />
      </SpinnerContainer>
    );
  }

  return (
    <Router>
      <div className="App">
        {telegramUserId ? (
          <>
            <Routes>
              <Route
                path="/"
                element={
                  <Slots
                    paymentModalVisible={paymentModalVisible}
                    setPaymentModalVisible={setPaymentModalVisible}
                  />
                }
              />
              <Route
                path="/referral"
                element={
                  <Referral
                    telegramUserId={telegramUserId}
                    paymentModalVisible={paymentModalVisible}
                    setPaymentModalVisible={setPaymentModalVisible}
                  />
                }
              />
              <Route
                path="/airdrops"
                element={
                  <Airdrops
                    paymentModalVisible={paymentModalVisible}
                    setPaymentModalVisible={setPaymentModalVisible}
                  />
                }
              />
              <Route
                path="/tasks"
                element={
                  <Tasks
                    paymentModalVisible={paymentModalVisible}
                    setPaymentModalVisible={setPaymentModalVisible}
                  />
                }
              />
              <Route
                path="/leaderboard"
                element={
                  <Leaderboard
                    telegramUserId={telegramUserId}
                    paymentModalVisible={paymentModalVisible}
                    setPaymentModalVisible={setPaymentModalVisible}
                  />
                }
              />
            </Routes>
            <BottomNav />
          </>
        ) : (
          <LandingPage />
        )}
      </div>
    </Router>
  );
};

export default App;
