import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import styled, { createGlobalStyle } from 'styled-components';
import ErrorBoundary from './components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || Buffer;

const queryClient = new QueryClient();

export const GlobalStyles = createGlobalStyle`
  body {
    padding: 20px;
    font-family: 'Outfit', sans-serif;
    background: linear-gradient(180deg, #090d1e 0%, #102130 100%, #1a1524 20%);
    background-color: #090d1e;
    color: #d7d7d7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }
  button, input, textarea {
    font-family:  'Wendy One', sans-serif;
  }


  h1, h2, h3, h4, h5, h6 {
    font-family: 'Outfit', sans-serif;
  }
`;

const ToastWrapper = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'custom-toast',
  bodyClassName: 'toast-body',
})`
  .custom-toast {
    background: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    padding: 12px 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    min-height: 40px;
    max-width: 300px;
  }

  .Toastify__toast-container {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
  }

  .Toastify__toast--success {
    background: #4caf50;
  }

  .Toastify__toast--error {
    background: #ff4d4f;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <ErrorBoundary>
      <ToastWrapper
        autoClose={1500}
        closeOnClick
        pauseOnHover={false}
        draggable={false}
        position="top-center"
      />
      <QueryClientProvider client={queryClient}>
        <TonConnectUIProvider manifestUrl={process.env.REACT_APP_MANIFEST_URL}>
          <App />
        </TonConnectUIProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
