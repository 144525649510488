import { mainApiInstance } from '../utils/createApiInstance';
import { useQueryCustom } from '../utils/useQueryCustom';

export type LeaderboardUserType = {
  balance: number;
};

const getUserBalance = async () => {
  return await mainApiInstance.get<LeaderboardUserType>(`balance`, {});
};

export const useGetUserBalance = () => {
  return useQueryCustom<LeaderboardUserType>(
    ['balance'],
    () => getUserBalance(),
    {}
  );
};
