import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  padding?: number;
  size?: number;
  color?: string;
  fontWeight?: string;
}

const TitleTextBox = styled.span<{
  padding?: number;
  size?: number;
  color?: string;
  fontWeight?: string;
}>`
  padding: ${({ padding }) => `${padding}px` || '0'};
  font-size: ${({ size }) => `${size}px` || '18px'};
  color: ${({ color }) => color || '#7a7b85'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
`;

const TitleText: React.FC<Props> = ({
  title,
  size,
  color,
  padding,
  fontWeight,
}) => {
  return (
    <TitleTextBox
      color={color}
      padding={padding}
      size={size}
      fontWeight={fontWeight}
    >
      {title}
    </TitleTextBox>
  );
};

export default TitleText;
