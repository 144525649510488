import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type AuthState = {
  sessionToken: string | null;
  setTokens: ({ sessionToken }: { sessionToken: string }) => void;
  clearTokens: () => void;
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      sessionToken: null,
      setTokens: (tokens) => set(() => ({ sessionToken: tokens.sessionToken })),
      clearTokens: () => set(() => ({ sessionToken: null })),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
