import React from 'react';

export const StarSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 84 81"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-190.000000, -73.000000)" fill="#ffff00">
          <defs>gradientPlaceholder</defs>
          <path d="M217.584175 95.7198452l10.102137-20.1302773C228.851735 73.2672578 231.669488 72.3342655 233.979937 73.5056713 234.886993 73.9655512 235.620771 74.7092927 236.070698 75.6248193l9.540513 19.41335C246.390574 96.6240434 247.914247 97.7068442 249.661599 97.9165844L269.640156 100.314675C272.352386 100.640233 274.288515 103.114133 273.96462 105.840286 273.831224 106.963049 273.321003 108.006556 272.518147 108.798619l-15.7911 15.578821C256.089452 125.006464 255.780497 125.898568 255.89167 126.789576L258.515919 147.821772C258.891602 150.832711 256.767771 153.579675 253.772209 153.957287 252.636962 154.100393 251.485656 153.881854 250.480518 153.332463L233.796907 144.21349C232.589892 143.553757 231.136827 143.534799 229.913183 144.162822l-17.2854 8.871545C210.195073 154.282929 207.215982 153.312865 205.973797 150.867667 205.509027 149.952785 205.337643 148.915649 205.483212 147.898872L206.864713 138.249309C207.540411 133.529666 210.458736 129.435705 214.68762 127.274973L233.885915 117.465678C234.398537 117.203755 234.602854 116.57373 234.342269 116.058476 234.140381 115.659283 233.708181 115.433429 233.267119 115.496635l-23.479483 3.364749C206.198378 119.375746 202.558336 118.361996 199.744254 116.064306L191.92377 109.678904C189.692048 107.856709 189.352516 104.561073 191.165405 102.317895 192.008458 101.274743 193.218498 100.597614 194.544752 100.426837L214.594132 97.8451554C215.880032 97.679575 217.000319 96.8832795 217.584175 95.7198452z" />
        </g>
      </g>
    </svg>
  );
};
