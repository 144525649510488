import { UseMutationOptions } from '@tanstack/react-query';
import { ICustomErrorResponse } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';

type InitiatePaymentResponseObject = {
  transactionId: string;
  tonAmount: number;
};

type InitiatePaymentRequestDto = {
  item_id: string;
};

export type InitiateTonTransactionParams = Omit<
  UseMutationOptions<
    InitiatePaymentResponseObject,
    ICustomErrorResponse,
    InitiatePaymentRequestDto
  >,
  'mutateFn'
>;

const initiateTonTransaction = async (params: InitiatePaymentRequestDto) => {
  return await mainApiInstance.post<InitiatePaymentResponseObject>(
    'payments/initiate-ton-transaction',
    {
      body: JSON.stringify(params),
    }
  );
};

export const useInitiateTonTransaction = (
  params: InitiateTonTransactionParams
) => {
  const { mutateAsync, isLoading } = useMutationCustom(
    ['initiateTonTransaction'],
    initiateTonTransaction,
    params
  );

  return { initiateTonTransaction: mutateAsync, isLoading };
};
