import React from 'react';
import styled from 'styled-components';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SyntheticEvent } from 'react';

const StyledBottomNavigation = styled(BottomNavigation)`
  && {
    position: fixed;
    bottom: 24px;
    left: 50%;
    height: 70px;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    max-width: 600px;
    background-color: #12142d !important;
    border-radius: 50px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    padding: 0 15px;
  }
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)<{
  grow?: number;
}>`
  && {
    color: #80818c;
    min-width: 0;
    flex-grow: ${(props) => props.grow || 1};
    padding: 6px 14px;

    @media (max-width: 480px) {
      padding: 4px 12px;

      .fa-solid {
        font-size: 18px;
      }
    }

    @media (min-width: 481px) and (max-width: 768px) {
      padding: 5px 14px;
      .MuiBottomNavigationAction-label {
        font-size: 14px;
      }
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }

    @media (min-width: 769px) {
      padding: 6px 12px;
      .MuiBottomNavigationAction-label {
        font-size: 16px;
      }
      .MuiSvgIcon-root {
        font-size: 24px;
      }
    }
  }

  &.Mui-selected {
    && {
      color: #d7d7d7 !important;
    }
  }
`;

const GradientOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to top, #010101, rgba(0, 0, 0, 0));
  z-index: 999;
  pointer-events: none;
`;

const BottomNav: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleNavigation = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/referral');
        break;
      case 2:
        navigate('/airdrops');
        break;
      case 3:
        navigate('/tasks');
        break;
      case 4:
        navigate('/leaderboard');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <>
      <GradientOverlay />
      <StyledBottomNavigation
        value={value}
        onChange={handleNavigation}
        showLabels
      >
        <StyledBottomNavigationAction
          grow={0.8}
          label="Slots"
          icon={
            <i
              className="fa-solid fa-slot-machine"
              style={{ fontSize: '18px' }}
            ></i>
          }
        />
        <StyledBottomNavigationAction
          grow={0.8}
          label="Referral"
          icon={
            <i
              className="fa-solid fa-people-group"
              style={{ fontSize: '18px' }}
            ></i>
          }
        />
        <StyledBottomNavigationAction
          grow={0.8}
          label="Airdrops"
          icon={
            <i className="fa-solid fa-coin" style={{ fontSize: '18px' }}></i>
          }
        />
        <StyledBottomNavigationAction
          grow={0.8}
          label="Tasks"
          icon={
            <i
              className="fa-solid fa-list-check"
              style={{ fontSize: '18px' }}
            ></i>
          }
        />
        <StyledBottomNavigationAction
          grow={1.3}
          label="Leaderboard"
          icon={
            <i
              className="fa-solid fa-trophy-star"
              style={{ fontSize: '18px' }}
            ></i>
          }
        />
      </StyledBottomNavigation>
    </>
  );
};

export default BottomNav;
