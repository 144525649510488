import React from 'react';
import styled from 'styled-components';
import CustomButton from './CustomButton';
import { FaUserFriends } from 'react-icons/fa';

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;

  svg {
    font-size: 18px;
  }
`;

interface ShareButtonProps {
  botUsername: string;
  userId: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ botUsername, userId }) => {
  const referralLink = `https://t.me/${botUsername}?start=${userId}`;

  const handleInviteFriends = () => {
    window.open(
      `https://t.me/share/url?url=${encodeURIComponent(
        referralLink
      )}&text=${encodeURIComponent('Join me on this amazing app!')}`,
      '_blank'
    );
  };

  return (
    <CustomButton
      onClick={handleInviteFriends}
      width={48}
      // @ts-ignore
      text={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
          }}
        >
          <IconWrapper>
            <FaUserFriends />
          </IconWrapper>
          Invite Friends
        </div>
      }
      fontWeight="bold"
    />
  );
};

export default ShareButton;
