import React, { useEffect, useRef, useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import IconBox from '../components/IconBox';
import TitleText from '../components/TitleText';
import styled from 'styled-components';
import LeaderboardItem from '../components/LeaderboardItem';
import { useGetLeaderboard } from '../services/leaderboard';
import CoinIcon from '../assets/coins.png';
import LeaderboardSkeletonItem from '../components/LeaderboardSkeletonItem';

const useLeaderboardController = () => {
  const { data } = useGetLeaderboard();
  return {
    leaderboardList: data?.records ?? [],
  };
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ValueText = styled.span`
  color: #d7d7d7;
  font-size: 22px;
  margin-top: 8px;
  font-weight: bold;
`;

const TextContainer = styled.div<{ scrolled?: boolean }>`
  display: flex;
  flex-direction: ${({ scrolled }) => (scrolled ? 'row' : 'column')};
  justify-content: ${({ scrolled }) => (scrolled ? 'space-between' : 'center')};
  align-items: center;
  margin-bottom: 20px;
  text-align: ${({ scrolled }) => (scrolled ? 'left' : 'center')};
  transition: all 0.3s ease-in-out;
  padding: 0 20px;
  height: ${({ scrolled }) => (scrolled ? '7vh' : 'auto')};
`;

const UserSpotContainer = styled.div`
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: 'transparent';
  z-index: 3000;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

const HighlightedItem = styled.div<{ isUser?: boolean }>`
  background: ${({ isUser }) =>
    isUser
      ? `linear-gradient(90deg, rgba(11, 129, 127, 1), rgba(188, 107, 28, 1))`
      : '#2a3449'};
  color: ${({ isUser }) => (isUser ? '#fff' : '#d7d7d7')};
  border-radius: 8px;
  border: none;
`;

const ScrollableList = styled.div<{ paddingBottom: number; itemCount: number }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: ${({ itemCount }) => (itemCount < 10 ? 'hidden' : 'auto')};
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  max-height: calc(100vh - 200px);
  min-height: 300px;

  @media (max-width: 768px) {
    max-height: calc(100vh - 180px);
    min-height: 250px;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 160px);
    min-height: 220px;
  }
`;

// const leaderboardList = [
//   {
//     user_id: '1',
//     username: 'rolloMtsariashvili',
//     first_name: 'Rollo',
//     token_amount: 41900,
//     last_login_date: '2024-11-21',
//     login_streak: 50,
//     leaderboard: 'Gold',
//   },

//   {
//     user_id: '2',
//     username: 'EarBiter',
//     first_name: 'Mike',
//     token_amount: 21000,
//     last_login_date: '2024-11-20',
//     login_streak: 45,
//     leaderboard: 'Gold',
//   },
//   {
//     user_id: '3',
//     username: 'GomezSelena',
//     first_name: 'Selena',
//     token_amount: 7200,
//     last_login_date: '2024-11-19',
//     login_streak: 30,
//     leaderboard: 'Silver',
//   },
//   {
//     user_id: '4',
//     username: 'ddd',
//     first_name: 'Daqo',
//     token_amount: 6000,
//     last_login_date: '2024-11-18',
//     login_streak: 28,
//     leaderboard: 'Silver',
//   },

//   {
//     user_id: '5',
//     username: 'hvjgvj',
//     first_name: 'Mosa',
//     token_amount: 4200,
//     last_login_date: '2024-11-17',
//     login_streak: 25,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '6',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '7',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },
//   {
//     user_id: '8',
//     username: 'mariamekhrishvili',
//     first_name: 'M',
//     token_amount: 4200,
//     last_login_date: '2024-11-17',
//     login_streak: 25,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '9',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '10',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '11',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },

//   {
//     user_id: '12',
//     username: 'DonaldTrump',
//     first_name: 'Donald',
//     token_amount: 2100,
//     last_login_date: '2024-11-16',
//     login_streak: 20,
//     leaderboard: 'Bronze',
//   },
// ];

const Leaderboard = ({
  telegramUserId,
  paymentModalVisible,
  setPaymentModalVisible,
}: {
  telegramUserId: string | number | null;
  paymentModalVisible: boolean;
  setPaymentModalVisible: (b: boolean) => void;
}) => {
  const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
  const username = telegramUser?.username || '';
  const firstName = telegramUser?.first_name || '';

  const { leaderboardList } = useLeaderboardController();

  const userSpot = leaderboardList.find(
    (user) => user.username === username || user.first_name === firstName
  );

  const userIndex = leaderboardList.findIndex(
    (user) => user.username === username || user.first_name === firstName
  );
  const isLoading = !leaderboardList || leaderboardList.length === 0;

  const [showUserSpot, setShowUserSpot] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [userSpotHeight, setUserSpotHeight] = useState(0);
  const userSpotRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!listRef.current) return;

      const listElement = listRef.current;
      const scrollTop = listElement.scrollTop;

      setScrolled(scrollTop > 50);

      if (userIndex >= 0 && userIndex < leaderboardList.length) {
        const items = listElement.children;
        const userItem = items[userIndex] as HTMLElement;

        if (!userItem) return;

        const userItemTop = userItem.getBoundingClientRect().top;
        const userItemBottom = userItem.getBoundingClientRect().bottom;

        const listTop = listElement.getBoundingClientRect().top;
        const listBottom =
          listElement.getBoundingClientRect().bottom -
          (window.innerHeight - listElement.getBoundingClientRect().bottom) -
          100;

        const isUserFullyVisible =
          userItemTop >= listTop && userItemBottom <= listBottom;

        // Show user spot only if the user is not fully visible
        setShowUserSpot(!isUserFullyVisible);
      } else {
        setShowUserSpot(true);
      }
    };

    const listElement = listRef.current;
    listElement?.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      listElement?.removeEventListener('scroll', handleScroll);
    };
  }, [userIndex, leaderboardList.length]);

  return (
    <PageContainer>
      <HeaderContainer>
        <InfoHeader
          paymentModalVisible={paymentModalVisible}
          setPaymentModalVisible={setPaymentModalVisible}
        />
      </HeaderContainer>
      <ContentContainer>
        <TextContainer scrolled={scrolled}>
          <IconBox isIconBorder iconSrc={CoinIcon} isScrolled={scrolled} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: scrolled ? 'flex-end' : 'center',
            }}
          >
            <TitleText padding={scrolled ? 0 : 10} title={'Top Scores'} />
          </div>
        </TextContainer>

        <ScrollableList
          ref={listRef}
          paddingBottom={showUserSpot ? userSpotHeight + 150 : 150}
          itemCount={leaderboardList.length}
        >
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <LeaderboardSkeletonItem key={index} />
              ))
            : leaderboardList.slice(0, 10).map((user, i) => (
                <HighlightedItem
                  key={user.user_id}
                  isUser={
                    user.username === username || user.first_name === firstName
                  }
                >
                  <LeaderboardItem
                    user={user}
                    index={i}
                    isUser={
                      user.username === username ||
                      user.first_name === firstName
                    }
                  />
                </HighlightedItem>
              ))}
        </ScrollableList>
      </ContentContainer>

      {showUserSpot && userSpot && (
        <UserSpotContainer ref={userSpotRef}>
          <HighlightedItem isUser>
            <LeaderboardItem user={userSpot} isUser index={userIndex} />
          </HighlightedItem>
        </UserSpotContainer>
      )}
    </PageContainer>
  );
};

export default Leaderboard;
