import React, { useEffect, useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import TitleText from '../components/TitleText';
import styled from 'styled-components';
import airdropIcon from '../assets/airdrop.png';
import AirdropSkeletonLoader from '../components/AirdropSkeletonLoader';
import { useGetClaims } from '../services/claims';
import TaskSkeletonLoader from '../components/TaskSkeletonLoader';
import InfoBox from '../components/InfoBox';
import { buttonStatus, TaskStatus } from '../type';
import toncoinIcon from '../assets/toncoin.jpg';
import { toast } from 'react-toastify';
import { useGenerateChallenge } from '../services/challenge';
import { ProofType, useClaimReward } from '../services/claimReward';
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useQueryClient } from '@tanstack/react-query';

const AirdropTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;

  @media (max-width: 768px) {
    height: 70vh;
  }

  @media (max-width: 480px) {
    height: auto;
    padding: 1rem;
  }
`;

const Icon = styled.img`
  max-width: 80%;
  height: auto;
  object-fit: contain;
  margin-top: 2rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 90%;
    margin-top: 1.5rem;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    margin-top: 1rem;
  }
`;

const ResponsiveTitleText = styled(TitleText)`
  font-size: ${({ size }: { size?: number }) => (size ? `${size}px` : '16px')};

  @media (max-width: 768px) {
    font-size: ${({ size }: { size?: number }) =>
      size ? `${Math.max(size - 4, 12)}px` : '14px'};
  }

  @media (max-width: 480px) {
    font-size: ${({ size }: { size?: number }) =>
      size ? `${Math.max(size - 8, 10)}px` : '12px'};
  }
`;

const ScrollableContainer = styled.div`
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 80px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
  }
`;

const Airdrops = ({
  paymentModalVisible,
  setPaymentModalVisible,
}: {
  paymentModalVisible: boolean;
  setPaymentModalVisible: (paymentModalVisible: boolean) => void;
}) => {
  const queryClient = useQueryClient();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { data: claimsList, isLoading } = useGetClaims();
  const [claimStatuses, setClaimStatuses] = useState<Record<string, string>>(
    {}
  );
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const wallet = useTonWallet();

  const { generateChallenge, isLoading: isChallengeLoading } =
    useGenerateChallenge({});
  const { claimReward, isLoading: isClaimRewardLoading } = useClaimReward({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });

  useEffect(() => {
    const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
      if (!wallet) {
        return;
      }

      const proofData = wallet.connectItems?.tonProof;
      if (proofData && 'proof' in proofData) {
        claimReward({
          proof: proofData.proof as ProofType,
          public_key: wallet.account.publicKey ?? '',
          address: wallet.account.address ?? '',
        });
      } else {
        console.log('No tonProof in connectItems');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleClaimClick = async (claimId: string) => {
    tonConnectUI.disconnect();
    tonConnectUI.setConnectRequestParameters({
      state: 'loading',
    });

    const { challenge } = await generateChallenge({ claim_id: claimId }); // backend call generating challenge.
    if (!challenge) {
      tonConnectUI.setConnectRequestParameters(null);
      return;
    }

    console.log(toast.success(`asdasdasd ${wallet}`));
    tonConnectUI.setConnectRequestParameters({
      state: 'ready',
      value: {
        tonProof: challenge,
      },
    });

    await tonConnectUI.openModal();
  };

  useEffect(() => {
    const img = new Image();
    img.src = airdropIcon;

    if (img.complete) {
      setIsImageLoading(false);
    } else {
      img.onload = () => setIsImageLoading(false);
      img.onerror = () => setIsImageLoading(false);
    }
  }, []);

  return (
    <>
      <InfoHeader
        paymentModalVisible={paymentModalVisible}
        setPaymentModalVisible={setPaymentModalVisible}
      />
      {isImageLoading ? (
        <AirdropSkeletonLoader />
      ) : claimsList?.records ? (
        isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <TaskSkeletonLoader key={index} />
          ))
        ) : (
          <ScrollableContainer>
            {claimsList?.records.map((task) => {
              const buttonState =
                claimStatuses[task.claim_id] || buttonStatus.readyToClaim;
              const buttonText =
                buttonState === buttonStatus.completed ? 'Done' : 'Claim';

              return (
                <InfoBox
                  key={task.claim_id}
                  title={'TON'}
                  value={task.win_amount}
                  buttonText={buttonText}
                  buttonState={buttonState as TaskStatus}
                  onClick={() => handleClaimClick(task.claim_id)}
                  isIcon
                  iconSrc={toncoinIcon}
                />
              );
            })}
          </ScrollableContainer>
        )
      ) : (
        <CenteredContainer>
          <AirdropTextContainer>
            <ResponsiveTitleText color="#d7d7d7" size={24} title={'Airdrop'} />
            <ResponsiveTitleText
              padding={20}
              color="#d7d7d7"
              size={30}
              title={'No Airdrops to show'}
            />
          </AirdropTextContainer>
          <Icon src={airdropIcon} />
        </CenteredContainer>
      )}
    </>
  );
};

export default Airdrops;
