import React, { useState } from 'react';
import styled from 'styled-components';
import CustomButton from './CustomButton';
interface MultiplyWinModalProps {
  show: boolean;
  onClose: () => void;
}

const ModalBackground = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-end;
  z-index: 3000;
`;

const ModalContainer = styled.div`
  background: rgba(42, 52, 73, 1);
  width: 100%;
  max-width: 400px;
  border-radius: 15px 15px 0 0;
  padding: 20px;
  animation: slide-up 0.3s ease-out;

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 10px;
`;

const ModalTitle = styled.h2`
  color: #d7d7d7;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #aaa;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

const ModalDescription = styled.p`
  color: rgba(215, 215, 215, 0.8);
  text-align: center;
  font-size: 1rem;
  margin: 15px 0;
`;

const OptionList = styled.div`
  margin: 20px 0;
`;

const Option = styled.label<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: 'rgba(38, 48, 68, 1)';
  border: 2px solid
    ${({ isSelected }) => (isSelected ? '#0a7876' : 'rgba(38, 48, 68, 1)')};
  border-radius: 10px;
  padding: 15px;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#d7d7d7')};
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: border-color 0.3s;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #095d5b;
    border-radius: 50%;
    background: transparent;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &:checked {
      background-color: #095d5b;
      border-color: #095d5b;
    }

    &:hover {
      border-color: #0a7876;
    }
  }
`;

const MultiplyButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MultiplyWinModal: React.FC<MultiplyWinModalProps> = ({
  show,
  onClose,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const options = [
    { label: '3x - $1.5', value: '1.5' },
    { label: '5x - $2', value: '2' },
  ];

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleBuyClick = () => {
    if (selectedOption) {
      console.log(`Buying multiplier: ${selectedOption}`);
    }
  };

  return (
    <ModalBackground show={show} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalTitle>Multiply win</ModalTitle>
        <ModalDescription>
          You can choose below how to multiply your win
        </ModalDescription>
        <OptionList>
          {options.map((option) => (
            <Option
              key={option.value}
              isSelected={selectedOption === option.value}
            >
              {option.label}
              <input
                type="radio"
                name="multiplier"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
          ))}
        </OptionList>
        <MultiplyButtonContainer>
          <CustomButton
            text={`Buy ($${selectedOption ? selectedOption : ''})`}
            onClick={handleBuyClick}
            disabled={!selectedOption}
            width={100}
            fontWeight="bold"
          />
        </MultiplyButtonContainer>
      </ModalContainer>
    </ModalBackground>
  );
};

export default MultiplyWinModal;
