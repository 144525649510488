import { create } from 'zustand';

type UseMeStore = {
  balance: number;
  availableSpins: number;
  setBalance: (balance: number) => void;
  setAvailableSpins: (availableSpins: number) => void;
};

export const useMeStore = create<UseMeStore>()((set) => ({
  balance: 0,
  availableSpins: 0,
  setBalance: (balance: number) => set({ balance }),
  setAvailableSpins: (availableSpins: number) => set({ availableSpins }),
}));
