// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ICustomErrorResponse = {
  status: number;
  error: {
    message: string;
  };
};

export type SocialLinkType =
  | 'Facebook'
  | 'Twitter'
  | 'Instagram'
  | 'LinkedIn'
  | 'TikTok'
  | 'YouTube'
  | 'Discord';

export type TaskStatus =
  | 'readyToClaim'
  | 'open'
  | 'pendingApproval'
  | 'completed';

export type UserModelDto = {
  user_id: string;
  username?: string;
  first_name: string;
  token_amount: number;
  last_login_date: string;
  login_streak: number;
  leaderboard: string;
};

export type UserTask = {
  user_id: string;
  task_id: string;
  task_title: string;
  task_description: string;
  reward_amount: number;
  daily_increment_amount: number;
  is_daily_task: boolean;
  social_link: string;
  social_link_type: SocialLinkType;
  last_updated: string;
  daily_streak: number;
  status: TaskStatus;
};

export enum buttonStatus {
  readyToClaim = 'readyToClaim',
  open = 'open',
  pendingApproval = 'pendingApproval',
  completed = 'completed',
}

export type ReferralModel = {
  user_id: string;
  referral_id: string;
  referral_date: string;
  referral_username: string;
};

export const buyingOptionTypes = {
  '10Spins': '10Spins',
  '30Spins': '30Spins',
  '100Spins': '100Spins',
  '10SpinsTON': '10SpinsTON',
  '30SpinsTON': '30SpinsTON',
  '100SpinsTON': '100SpinsTON',
};

export const spinPrices = {
  '10SpinsTON': 0.01,
  '30SpinsTON': 0.025,
  '100SpinsTON': 0.07,
};

export type WebsocketPayload = {
  balance: number;
  availableSpins: number;
  message: string;
};

export type WebsocketEventType = MessageEvent<WebsocketPayload>;

export type UserClaim = {
  user_id: string;
  claim_id: string;
  win_amount: number;
  temporary_challenge: string | null;
  date: string;
};
