import { UseMutationOptions } from '@tanstack/react-query';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';
import { useQueryCustom } from '../utils/useQueryCustom';
import { useState } from 'react';
import { ICustomErrorResponse } from '../type';

interface SpinResult {
  row: string[];
  isWinningRow: boolean;
}

interface SpinResponse {
  result: SpinResult[];
  totalWin: number;
  balance: number;
  spinAmount: number;
}

type SpinMutationParams = Omit<
  UseMutationOptions<SpinResponse, ICustomErrorResponse, undefined, any>,
  'mutateFn'
>;

const spin = async (): Promise<SpinResponse> => {
  return mainApiInstance.post(`spin`, {});
};

export const useLazyGetSpin = () => {
  const [isTriggered, setIsTriggered] = useState(false);
  const { data, error, isLoading, refetch } = useQueryCustom(
    ['spin/1'],
    () => spin(),
    {
      enabled: isTriggered,
    }
  );

  const trigger = () => {
    setIsTriggered(true);
    refetch();
  };

  return { data, error, isLoading, trigger };
};

export const useSpin = (params: SpinMutationParams) => {
  const { mutateAsync, isLoading } = useMutationCustom(['spin'], spin, params);

  return { spin: mutateAsync, isLoading };
};
