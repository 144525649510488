import React from 'react';
import styled from 'styled-components';
import { UserModelDto } from '../type';

interface HeaderProps {
  index: number;
  user: UserModelDto;
  isUser?: boolean;
}

const HeaderContainer = styled.div<{ isUser?: boolean }>`
  background: ${({ isUser }) =>
    isUser
      ? `linear-gradient(90deg, rgba(11, 129, 127, 1), rgba(188, 107, 28, 1))`
      : '#2a3449'};
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: ${({ isUser }) => (isUser ? '#fff' : '#d7d7d7')};
  overflow: hidden;
`;

const LeftColumn = styled.span`
  font-size: 18px;
  text-align: left;
  width: 10%;
  flex-shrink: 0;
  white-space: nowrap;
`;

const CenterColumn = styled.span`
  font-size: 18px;
  text-align: center;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RightColumn = styled.span`
  font-size: 18px;
  text-align: right;
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
`;

const LeaderboardItem: React.FC<HeaderProps> = ({ user, index, isUser }) => {
  return (
    <HeaderContainer isUser={isUser}>
      <LeftColumn>{index + 1}</LeftColumn>
      <CenterColumn>{user.username || user.first_name}</CenterColumn>
      <RightColumn>{user.token_amount}</RightColumn>
    </HeaderContainer>
  );
};

export default LeaderboardItem;
