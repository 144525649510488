import { UseMutationOptions } from '@tanstack/react-query';
import { useMutationCustom } from '../utils/useMutationCustom';
import { ICustomErrorResponse } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';

type AuthenticateResponseObject = {
  sessionToken: string;
  balance: number;
  spinAmount: number;
};

type AuthenticateRequestDto = {
  initData: string;
  referrer: string | null;
};

type AuthenticationMutationParams = Omit<
  UseMutationOptions<
    AuthenticateResponseObject,
    ICustomErrorResponse,
    AuthenticateRequestDto
  >,
  'mutationFn'
>;

const auth = async (params: AuthenticateRequestDto) => {
  return await mainApiInstance.post<AuthenticateResponseObject>(
    'authentication/authenticate',
    {
      body: JSON.stringify(params),
    }
  );
};

export const useAuthenticateMutation = (
  params: AuthenticationMutationParams
) => {
  const { mutateAsync, isLoading } = useMutationCustom(['auth'], auth, {
    ...params,
  });

  return { authenticate: mutateAsync, isLoading };
};
