import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomButton from './CustomButton';
import { useCreatePayment } from '../services/createPayment';
import { toast } from 'react-toastify';
import { buyingOptionTypes, spinPrices } from '../type';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
// import { useCreatePayment } from '../services/createPayment';
// import { toast } from 'react-toastify';
// import { Telegram } from '@mui/icons-material';
import { beginCell } from '@ton/ton';
import { useInitiateTonTransaction } from '../services/initiateTonTransaction';
import { StarSvg } from '../assets/svg/StarSvg';
import { TonSvg } from '../assets/svg/TonSvg';

function encodeTextPayload(text: string) {
  return beginCell().storeUint(0, 32).storeStringTail(text).endCell();
}

const SmallModalBackground = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 4000;
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;

const SmallModal = styled.div`
  background: rgba(42, 52, 73, 1);
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  width: 320px;
`;

const ModalBackground = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: flex-end;
  justify-content: center;
  z-index: 3000;
`;

const ModalContainer = styled.div`
  background: rgba(42, 52, 73, 1);
  width: 100%;
  max-width: 400px;
  border-radius: 15px 15px 0 0;
  padding: 20px;
  animation: slide-up 0.3s ease-out;
  position: relative;

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 0 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #aaa;
  font-size: 2.5rem;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 0;
`;

const ModalTitle = styled.h2`
  color: #d7d7d7;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
`;

const ModalDescription = styled.p`
  color: rgba(215, 215, 215, 0.8);
  text-align: center;
  font-size: 1rem;
  margin: 15px 0;
`;

const OptionList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;
`;

const Option = styled.label<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(38, 48, 68, 1);
  border: 2px solid
    ${({ isSelected }) => (isSelected ? '#0a7876' : 'rgba(38, 48, 68, 1)')};
  border-radius: 10px;
  padding: 15px;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#d7d7d7')};
  cursor: pointer;
  font-size: 1rem;
  transition: border-color 0.3s;
  text-align: center;
  width: 100%;

  input {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #095d5b;
    border-radius: 50%;
    background: transparent;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &:checked {
      background-color: #095d5b;
      border-color: #095d5b;
    }

    &:hover {
      border-color: #0a7876;
    }
  }
`;

const MultiplyButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface BuySpinsModalProps {
  show: boolean;
  onClose: () => void;
}

const BuySpinsModal: React.FC<BuySpinsModalProps> = ({ show, onClose }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isTonSelected, setIsTonSelected] = useState(false);
  const tonAddress = useTonAddress();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setIsTonSelected(value.includes('TON'));
  };

  const { createPayment, isLoading } = useCreatePayment({
    onSuccess: (data: { invoice_url: string }) => {
      if (data.invoice_url && window.Telegram.WebApp.openInvoice) {
        window.Telegram.WebApp.openInvoice(data.invoice_url);
      }
    },
    onError: (error) => {
      toast.error(`Failed to create payment ${error}`);
      console.error('Payment error:', error);
    },
  });

  const { initiateTonTransaction } = useInitiateTonTransaction({});

  // Example usage
  const handleCreatePayment = useCallback(async () => {
    // if (selectedOption && buyingOptionTypes[selectedOption]) {
    if (selectedOption && Object.keys(spinPrices).includes(selectedOption)) {
      initiateTonTransaction({
        item_id: selectedOption,
      }).then((res) => {
        tonConnectUI
          .sendTransaction({
            validUntil: Math.floor(Date.now() / 1000) + 60 * 60,
            messages: [
              {
                address: process.env.REACT_APP_TON_WALLET ?? '',
                amount: (res.tonAmount * 1e9).toString(),
                payload: encodeTextPayload(res.transactionId)
                  .toBoc()
                  .toString('base64'),
              },
            ],
          })
          .then((res) => {
            onClose();
            toast.info(`You will receive your spins soon! 🚀`);
          });
      });

      return;
    }

    if (selectedOption) {
      createPayment({ item_id: selectedOption });
    }
  }, [createPayment, selectedOption]);

  const [tonConnectUI, setOptions] = useTonConnectUI();

  useEffect(() => {
    const currentIsConnectedStatus = tonConnectUI.connected;
    setWalletConnected(currentIsConnectedStatus);
  }, [tonConnectUI.connected, tonAddress]);

  const handleTonConnect = async () => {
    try {
      if (!tonAddress) {
        await tonConnectUI.openModal();
        onClose();
        setWalletConnected(true);
        setError(null);
      }
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes('WalletAlreadyConnectedError')
      ) {
        setError('Wallet is already connected.');
      } else {
        setError('Connect wallet first.');
      }
    }
  };

  const handleDisconnectWallet = async () => {
    await tonConnectUI.disconnect();
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <ModalBackground show={show} onClick={onClose}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </ModalHeader>
          <ModalTitle>Buy spins</ModalTitle>
          <ModalDescription>
            You can choose and buy spins below
          </ModalDescription>

          <OptionList>
            <Option
              isSelected={selectedOption === buyingOptionTypes['10Spins']}
            >
              10 spins <StarSvg />
              15
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['10Spins']}
                checked={selectedOption === buyingOptionTypes['10Spins']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
            <Option
              isSelected={selectedOption === buyingOptionTypes['10SpinsTON']}
            >
              10 spins <TonSvg />
              0.07
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['10SpinsTON']}
                checked={selectedOption === buyingOptionTypes['10SpinsTON']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
            <Option
              isSelected={selectedOption === buyingOptionTypes['30Spins']}
            >
              30 spins <StarSvg /> 35
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['30Spins']}
                checked={selectedOption === buyingOptionTypes['30Spins']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
            <Option isSelected={selectedOption === '30 spins - $1'}>
              30 spins <TonSvg />
              0.14
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['30SpinsTON']}
                checked={selectedOption === buyingOptionTypes['30SpinsTON']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
            <Option
              isSelected={selectedOption === buyingOptionTypes['100Spins']}
            >
              100 spins <StarSvg /> 85
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['100Spins']}
                checked={selectedOption === buyingOptionTypes['100Spins']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
            <Option isSelected={selectedOption === '100 spins - $3'}>
              100 spins <TonSvg />
              0.34
              <input
                type="radio"
                name="spins"
                value={buyingOptionTypes['100SpinsTON']}
                checked={selectedOption === buyingOptionTypes['100SpinsTON']}
                onChange={(e) => handleOptionChange(e.target.value)}
              />
            </Option>
          </OptionList>
          <MultiplyButtonContainer>
            {selectedOption?.includes('TON') && (
              <CustomButton
                text={
                  tonAddress &&
                  walletConnected &&
                  selectedOption?.includes('TON')
                    ? 'Buy TON'
                    : 'Connect Wallet'
                }
                onClick={
                  tonAddress &&
                  walletConnected &&
                  selectedOption?.includes('TON')
                    ? handleCreatePayment
                    : handleTonConnect
                }
                width={100}
                fontWeight="bold"
              />
            )}

            {!selectedOption?.includes('TON') ? (
              <CustomButton
                width={100}
                fontWeight="bold"
                text={`Buy ${selectedOption || ''}`}
                onClick={handleCreatePayment}
                disabled={selectedOption?.includes('TON') && !selectedOption}
              />
            ) : null}
            {selectedOption?.includes('TON') &&
            walletConnected &&
            tonAddress ? (
              <CustomButton
                marginVertical={0.75}
                text={`Disconnect Wallet`}
                onClick={() => setIsConfirmModalOpen(true)}
                width={100}
                fontWeight="bold"
              />
            ) : null}
          </MultiplyButtonContainer>
        </ModalContainer>
      </ModalBackground>

      <SmallModalBackground show={isConfirmModalOpen}>
        <SmallModal>
          <CloseButton onClick={() => setIsConfirmModalOpen(false)}>
            &times;
          </CloseButton>
          <p>Are you sure you want to disconnect?</p>
          <CustomButton
            width={50}
            text="Yes"
            onClick={handleDisconnectWallet}
          />
          <CustomButton
            width={50}
            text="No"
            onClick={() => setIsConfirmModalOpen(false)}
          />
        </SmallModal>
      </SmallModalBackground>
    </>
  );
};

export default BuySpinsModal;
