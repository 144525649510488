import React from 'react';
import logo from '../assets/logo.png';

const LandingPage: React.FC = () => {
  return (
    <div style={styles.container}>
      <img src={logo} alt="BitSpin Logo" style={styles.logo} />
      <h1 style={styles.title}>
        🎰 Welcome to BitSpin: The Ultimate Telegram Slot Experience! 🎰
      </h1>
      <p style={styles.paragraph}>
        Dive into the world of BitSpin, a cutting-edge Telegram slot bot that
        transforms the thrill of gaming into a rewarding crypto adventure! With
        every spin, you can earn valuable tokens while enjoying classic slot
        gameplay.
      </p>
      <div style={styles.card}>
        <h2 style={styles.subheading}>🌟 Game Overview:</h2>
        <p style={styles.paragraph}>
          BitSpin offers you a unique opportunity to play slots in a fun and
          interactive way. You&apos;ll receive a limited number of spins daily,
          and each spin can earn you coins! The more coins you collect, the
          greater your share in our upcoming airdrop distribution.
        </p>
      </div>
      <div style={styles.card}>
        <h2 style={styles.subheading}>💰 Slot Mechanics:</h2>
        <p style={styles.paragraph}>
          Our slots feature classic three-line combinations, making it easy to
          hit the jackpot by matching three identical symbols. Here&apos;s what
          you can win:
        </p>
        <ul style={styles.list}>
          <li>Bitcoin Line: 1000 BS (our bot coin)</li>
          <li>Ethereum Line: 1000 BS</li>
          <li>Tone Line: 500 BS</li>
          <li>USD Line: 300 BS</li>
        </ul>
      </div>
      <div style={styles.card}>
        <h2 style={styles.subheading}>🚀 Airdrop Missions:</h2>
        <p style={styles.paragraph}>
          Complete daily and one-time tasks to boost your coin earnings! Each
          task has a set coin reward, adding an extra layer of excitement to
          your gameplay.
        </p>
      </div>
      <div style={styles.card}>
        <h2 style={styles.subheading}>🏆 Airdrop Distribution Priorities:</h2>
        <p style={styles.paragraph}>Your rewards are based on:</p>
        <ul style={styles.list}>
          <li>Number of spin purchases</li>
          <li>Number of completed tasks</li>
          <li>Utilization of daily spins</li>
          <li>Total number of coins collected</li>
          <li>Number of referrals</li>
        </ul>
      </div>
      <div style={styles.card}>
        <h2 style={styles.subheading}>🎯 Game Mission:</h2>
        <p style={styles.paragraph}>
          At BitSpin, we&apos;re redefining the gambling experience. Your play
          isn&apos;t just about luck; it&apos;s an investment in cryptocurrency!
          Our goal is to create a &quot;no-lose&quot; gaming environment, where
          every spin brings you closer to earning real crypto rewards.
        </p>
      </div>
      <div style={styles.card}>
        <h2 style={styles.subheading}>📅 Airdrop Timeline:</h2>
        <p style={styles.paragraph}>
          An estimated distribution starting in the second half of 2025.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center' as const,
    padding: '20px',
    color: '#fff',
    margin: '0 auto',
    maxWidth: '900px',
    minHeight: '100vh',
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '20px',
    fontWeight: 'bold' as const,
    lineHeight: '1.3',
    color: '#00d9ff',
    textShadow: '0 0 10px rgba(0, 217, 255, 0.7)',
  },
  subheading: {
    fontSize: '1.5rem',
    margin: '20px 0 10px',
    fontWeight: 'bold' as const,
  },
  paragraph: {
    fontSize: '1.1rem',
    lineHeight: '1.6',
    marginBottom: '20px',
    color: 'rgba(215, 215, 215, 0.5)',
  },
  card: {
    background: 'rgba(29, 32, 52, 1)',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
  list: {
    textAlign: 'left' as const,
    paddingLeft: '40px',
    marginBottom: '20px',
  },
  logo: {
    width: '150px',
    borderRadius: '50px',
  },
  '@media (maxWidth: 600px)': {
    title: {
      fontSize: '1.5rem',
    },
    subheading: {
      fontSize: '1.2rem',
    },
    paragraph: {
      fontSize: '1rem',
    },
    logo: {
      width: '100px',
    },
  },
};

export default LandingPage;
