import {
  MutationFunction,
  MutationKey,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query';
import { ICustomErrorResponse } from '../type';
import { toast } from 'react-toastify';

export const useMutationCustom = <
  TData = unknown,
  TVariables = void,
  TContext = unknown
>(
  queryKey: MutationKey,
  mutationFn: MutationFunction<TData, TVariables>,
  options: Omit<
    UseMutationOptions<TData, ICustomErrorResponse, TVariables, TContext>,
    'mutationFn'
  >
) => {
  return useMutation<TData, ICustomErrorResponse, TVariables, TContext>(
    queryKey,
    mutationFn,
    {
      ...options,
      onError: (error, variables, context) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- we need to call onError from options, but not return it
        options.onError && options.onError(error, variables, context);

        if (error.status !== 401) {
          toast.error(
            `there was an error in ${queryKey} ${JSON.stringify(error)}`
          );
        }

        if (error.status === 401) {
          // clearTokens();
        }
      },
      retry: false,
    }
  );
};
