import { useAuthStore } from '../store/useAuthStore';

class CodeError extends Error {
  code: string;

  status: number;

  error: { message: string; code: string };

  constructor(message: string, status: number, code: string) {
    super(message);
    this.code = code;
    this.status = status;
    this.error = { message, code };
  }
}

export const createApiInstance = (
  initialUrl: string,
  initialParams: RequestInit,
  abortControllerTimeout = 90 * 1000
) => {
  const abortController = new AbortController();
  const customFetch = async (
    method: string,
    url: string,
    params: RequestInit
  ) => {
    const { sessionToken } = useAuthStore.getState();

    const timeout = setTimeout(() => {
      abortController.abort();
    }, abortControllerTimeout);

    // @ts-ignore
    const headers: any = {
      ...initialParams.headers,
      ...params.headers,
      Authorization: `Bearer ${sessionToken}`,
    };

    if (!params.body || typeof params.body === 'string') {
      headers['Content-Type'] = 'application/json';
    }

    try {
      const data = await fetch(initialUrl + url, {
        ...initialParams,
        ...params,
        headers,
        signal: abortController.signal,
        method,
      });

      clearTimeout(timeout);

      if (!data.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw data;
      } else {
        return data;
      }
    } catch (error: any) {
      // any type is required here because of the throw data, temporary solution
      console.log(error.status);
      clearTimeout(timeout);

      if (error.status === 401) {
        throw new CodeError('Unauthorized', 401, 'UNAUTHORIZED');
      }
      if (error.name === 'AbortError') {
        throw new CodeError('Request timed out', 408, 'ABORT');
      }
      if (error.status === 500) {
        throw new CodeError(
          'Internal server error',
          500,
          'INTERNAL_SERVER_ERROR'
        );
      }
      if (error.status === 502) {
        throw new CodeError(
          'Internal server error',
          502,
          'INTERNAL_SERVER_ERROR'
        );
      }
      if (error.status === 413) {
        throw new CodeError('Content Too Large', 413, 'INTERNAL_SERVER_ERROR');
      }
      if (error.status === 504) {
        throw new CodeError(
          'Endpoint request timed out',
          504,
          'INTERNAL_SERVER_ERROR'
        );
      }
      if (error.status !== 400) {
        throw new CodeError('Something went wrong', error.status, 'ERROR');
      }

      const errorResponse = await error.json();

      console.log(errorResponse);
      throw new CodeError(
        errorResponse.error.message,
        error.status,
        errorResponse.error.code
      );
    }
  };

  return {
    get: async <T>(url: string, params: RequestInit): Promise<T> => {
      const response = await customFetch('GET', url, params);
      const data = await response.json();
      return data;
    },
    post: async <T>(url: string, params: RequestInit): Promise<T> => {
      const response = await customFetch('POST', url, params);
      const data = await response.json();
      return data;
    },
    put: async <T>(url: string, params: RequestInit): Promise<T> => {
      const response = await customFetch('PUT', url, params);
      const data = await response.json();
      return data;
    },
    delete: async <T>(url: string, params: RequestInit): Promise<T> => {
      const response = await customFetch('DELETE', url, params);
      const data = await response.json();
      return data;
    },
  };
};

console.log('process.env.BACKEND_URL', process.env.REACT_APP_BACKEND_URL);
export const mainApiInstance = createApiInstance(
  process.env.REACT_APP_BACKEND_URL
    ? `${process.env.REACT_APP_BACKEND_URL}/`
    : '',
  {}
);
