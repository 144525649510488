import React from 'react';
import styled from 'styled-components';

interface Props {
  isScrolled?: boolean;
  isIconBorder?: boolean;
  iconSrc?: string;
}

const IconContainer = styled.div<{
  isIconBorder?: boolean;
  isScrolled?: boolean;
}>`
  width: ${({ isScrolled }) => (isScrolled ? '3rem' : '3.75rem')};

  height: ${({ isScrolled }) => (isScrolled ? '3rem' : '3.75rem')};
  padding: ${({ isIconBorder }) => (isIconBorder ? '5px' : '0')};
  border-radius: ${({ isIconBorder }) => (isIconBorder ? '50%' : '0%')};
  border: ${({ isIconBorder }) =>
    isIconBorder ? '5px solid transparent' : '0px solid transparent'};
  background: ${({ isIconBorder }) =>
    isIconBorder
      ? `linear-gradient(#1e2034, #1e2034) padding-box,
        linear-gradient(to bottom, #0b827f, #4a7a5d 50%, #bc6c1d) border-box`
      : 'transparent'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures content stays within the bounds */
`;

const IconImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
`;

const IconBox: React.FC<Props> = ({
  isScrolled = false,
  iconSrc,
  isIconBorder = true,
}) => {
  return (
    <IconContainer isScrolled={isScrolled} isIconBorder={isIconBorder}>
      <IconImage src={iconSrc} alt="Icon" />
    </IconContainer>
  );
};

export default IconBox;
