import React, { useState } from 'react';
import styled from 'styled-components';
import InfoBox from '../components/InfoBox';
import { useGetTasks } from '../services/tasks';
import TaskModal from '../components/TaskModal';
import InfoHeader from '../components/InfoHeader';
import { TaskStatus, UserTask, buttonStatus } from '../type';
import { useMoveToNextStep } from '../services/nextstep';
import TaskIcon from '../assets/task.png';
import { useQueryClient } from '@tanstack/react-query';
import AnimatedSkeletonButton from '../components/AnimatedSkeletonButton';
import TaskSkeletonLoader from '../components/TaskSkeletonLoader';

const SectionTitle = styled.h2`
  color: #7a7b85;
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 80px;
`;

const Tasks = ({
  paymentModalVisible,
  setPaymentModalVisible,
}: {
  paymentModalVisible: boolean;
  setPaymentModalVisible: (paymentModalVisible: boolean) => void;
}) => {
  const { data: tasks, isLoading } = useGetTasks();
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<UserTask | null>(null);
  const [loadingTaskId, setLoadingTaskId] = useState<string | null>(null);

  const { moveToNextStep } = useMoveToNextStep({
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });

  const handleOpenModal = (task: UserTask) => {
    setSelectedTask(task);
    setShowModal(true);
  };

  const handleStartClickInModal = (task: UserTask) => {
    setLoadingTaskId(task.task_id);
    moveToNextStep({ task_id: task.task_id });
    setShowModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTask(null);
  };

  const getButtonProperties = (
    status: TaskStatus
  ): {
    buttonText: string;
    buttonState: TaskStatus | null;
  } => {
    if (status === buttonStatus.readyToClaim) {
      return { buttonText: 'Claim', buttonState: buttonStatus.readyToClaim };
    } else if (status === buttonStatus.open) {
      return { buttonText: 'Start', buttonState: status };
    } else if (status === buttonStatus.pendingApproval) {
      return {
        buttonText: 'Pending...',
        buttonState: buttonStatus.pendingApproval,
      };
    } else if (status === buttonStatus.completed) {
      return {
        buttonText: 'Done',
        buttonState: buttonStatus.completed,
      };
    }
    return { buttonText: '', buttonState: null };
  };

  return (
    <>
      <InfoHeader
        paymentModalVisible={paymentModalVisible}
        setPaymentModalVisible={setPaymentModalVisible}
      />
      <SectionTitle>Daily Tasks</SectionTitle>
      {isLoading
        ? Array.from({ length: 3 }).map((_, index) => (
            <TaskSkeletonLoader key={index} />
          ))
        : tasks?.records
            .filter((task) => task.is_daily_task)
            .map((task) => {
              const { buttonText, buttonState } = getButtonProperties(
                task?.status
              );

              return (
                <InfoBox
                  key={task.task_id}
                  title={task.task_title}
                  value={task.reward_amount}
                  // @ts-ignore
                  buttonText={
                    task.task_id === loadingTaskId ? (
                      <AnimatedSkeletonButton />
                    ) : (
                      buttonText
                    )
                  }
                  buttonState={buttonState}
                  onClick={() => {
                    if (task.status === buttonStatus.open) {
                      handleOpenModal(task);
                    } else {
                      moveToNextStep({
                        task_id: task.task_id,
                      });
                    }
                  }}
                  isIcon
                  iconSrc={TaskIcon}
                />
              );
            })}

      <TextContainer>
        <SectionTitle>Permanently</SectionTitle>
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <TaskSkeletonLoader key={index} />
            ))
          : tasks?.records
              .filter((task) => !task.is_daily_task)
              .map((task) => {
                const { buttonText, buttonState } = getButtonProperties(
                  task?.status
                );

                return (
                  <InfoBox
                    key={task.task_id}
                    title={task.task_title}
                    value={task.reward_amount}
                    // @ts-ignore
                    buttonText={
                      task.task_id === loadingTaskId ? (
                        <AnimatedSkeletonButton />
                      ) : (
                        buttonText
                      )
                    }
                    buttonState={buttonState}
                    onClick={() => {
                      if (task.status === buttonStatus.open) {
                        handleOpenModal(task);
                      } else {
                        moveToNextStep({
                          task_id: task.task_id,
                        });
                      }
                    }}
                    isIcon
                    iconSrc={TaskIcon}
                  />
                );
              })}
      </TextContainer>

      {showModal && selectedTask && (
        <TaskModal
          show={showModal}
          onClose={handleCloseModal}
          task={selectedTask}
          isIcon
          isLoading={loadingTaskId === selectedTask.task_id}
          moveToNextStep={() => handleStartClickInModal(selectedTask)}
          iconSrc={TaskIcon}
        />
      )}
    </>
  );
};

export default Tasks;
