import { UseMutationOptions } from '@tanstack/react-query';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';
import { ICustomErrorResponse } from '../type';

export type ProofType = {
  timestamp: number;
  domain: {
    lengthBytes: number;
    value: string;
  };
  signature: string;
  payload: string;
};

export type ClaimRewardRequestDto = {
  proof: ProofType;
  public_key: string;
  address: string;
};

export type ClaimRewardResponseObject = {
  message: string; // TOBE CHANGED
};

export type ClaimRewardParams = Omit<
  UseMutationOptions<
    ClaimRewardResponseObject,
    ICustomErrorResponse,
    ClaimRewardRequestDto
  >,
  'mutationFn'
>;
export const claimReward = async (params: ClaimRewardRequestDto) => {
  return await mainApiInstance.post<ClaimRewardResponseObject>('claims/claim', {
    body: JSON.stringify(params),
  });
};
export const useClaimReward = (params: ClaimRewardParams) => {
  const { mutateAsync, isLoading } = useMutationCustom(
    ['claimReward'],
    claimReward,
    params
  );

  return { claimReward: mutateAsync, isLoading };
};
