import React, { useState } from 'react';
import styled from 'styled-components';
import IconBox from '../components/IconBox';
import {
  MoveToNextStepMutationParams,
  NextStepRequestDto,
  NextStepResponseObject,
} from '../services/nextstep';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { ICustomErrorResponse, UserTask } from '../type';

const ModalBackground = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: flex-end;
  justify-content: center;
  z-index: 3000;
`;

const ModalContainer = styled.div`
  background: rgba(42, 52, 73, 1);
  width: 100%;
  max-width: 400px;
  border-radius: 15px 15px 0 0;
  padding: 20px;
  animation: slide-up 0.3s ease-out;
  position: relative;

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 0 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #aaa;
  font-size: 2.5rem;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 0;
`;

const ModalTitle = styled.h2`
  color: rgba(215, 215, 215, 1);
  text-align: center;
  margin: 10px 0;
`;

const InitialDescription = styled.p`
  color: rgba(215, 215, 215, 0.56);
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;

  span {
    color: #b6702f;
    font-weight: bold;
  }
`;

const SecondaryDescription = styled.p`
  color: rgba(215, 215, 215, 0.56);
  text-align: center;
  margin: 10px 0;
  font-size: 16px;
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
`;

const StyledInput = styled.input<{
  hasError: boolean;
  isSuccess: boolean;
  isFocused: boolean;
}>`
  width: 100%;
  padding: 10px;
  border: 2px solid
    ${({ hasError, isSuccess, isFocused }) =>
      hasError
        ? 'red'
        : isFocused
        ? 'rgba(5, 132, 132, 1)'
        : isSuccess
        ? 'rgba(64, 71, 87, 1)'
        : 'rgba(5, 132, 132, 1)'};
  border-radius: 10px;
  color: #ffffff;
  background: rgba(30, 32, 52, 1);
  padding-right: 30px;
  outline: none;
`;

const SuccessIcon = styled.span<{ isVisible: boolean }>`
  position: absolute;
  right: 10px;
  color: green;
  font-size: 20px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const ActionButton = styled.button`
  background-color: #112a36;
  border: 3px solid #07797a;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #0a6470;
  }

  &:active {
    transform: scale(0.97); /* Slightly reduce scale */
    background-color: #094859; /* Darken background color */
  }

  &:disabled {
    background-color: #333;
    border-color: #555;
    cursor: not-allowed;
    transform: none; /* No scale effect when disabled */
  }
`;

interface TaskModalProps {
  show: boolean;
  onClose: () => void;
  task: UserTask | null;
  isIcon: boolean;
  iconSrc?: string;
  moveToNextStep: () => void;
  isLoading: boolean;
}

const TaskModal: React.FC<TaskModalProps> = ({
  show,
  onClose,
  task,
  isIcon,
  iconSrc,
  moveToNextStep,
  isLoading,
}) => {
  const [isStarted, setIsStarted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (hasError) setHasError(false);

    if (isSuccess) setIsSuccess(false);
  };

  const handleStartClick = () => {
    if (task) {
      moveToNextStep();
      onClose(); // Close the modal immediately
    }
  };

  // const handleSubmitClick = () => {
  //   if (inputValue.trim() === '') {
  //     setHasError(true);
  //     setIsSuccess(false);
  //   } else {
  //     setHasError(false);
  //     setIsSuccess(true);
  //   }
  // };

  return (
    <ModalBackground show={show} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <IconContainer>{isIcon && <IconBox iconSrc={iconSrc} />}</IconContainer>
        <ModalTitle>{task?.task_title}</ModalTitle>

        <InitialDescription>
          You can click below on the button &ldquo;Start&rdquo; and receive{' '}
          <span>&ldquo;{task?.reward_amount}&rdquo;</span> when you join the
          BitSpin Telegram Group:
        </InitialDescription>
        {/* // ) : 
        // (
        //   <SecondaryDescription>
        //     {task?.postStartDescription}
        //   </SecondaryDescription>
        // )} */}
        <ActionButton onClick={handleStartClick} disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Start'}
        </ActionButton>
        {/* <>
            <InputField>
              <StyledInput
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder="Enter your input here..."
                hasError={hasError}
                isSuccess={isSuccess}
                isFocused={isFocused}
              />
              <SuccessIcon isVisible={isSuccess}>&#10003;</SuccessIcon>
            </InputField>
            {!isSuccess && (
              <ActionButton onClick={handleSubmitClick}>Submit</ActionButton>
            )}
          </>
     */}
      </ModalContainer>
    </ModalBackground>
  );
};

export default TaskModal;
