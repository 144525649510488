import { UserClaim } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useQueryCustom } from '../utils/useQueryCustom';

export type ClaimsResponseObject = {
  records: UserClaim[];
};

export const getUserClaims = async () => {
  return await mainApiInstance.get<ClaimsResponseObject>('claims/list', {});
};

export const useGetClaims = () => {
  return useQueryCustom<ClaimsResponseObject>(
    ['claims'],
    () => getUserClaims(),
    {}
  );
};
