import { ReferralModel } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useQueryCustom } from '../utils/useQueryCustom';

export type ReferralsResponseObject = {
  records: ReferralModel[];
};

export const getReferrals = async () => {
  return await mainApiInstance.get<ReferralsResponseObject>(`referrals`, {});
};

export const useGetReferrals = () => {
  return useQueryCustom<ReferralsResponseObject>(
    ['referrals'],
    () => getReferrals(),
    {}
  );
};
