import React, { useState, useEffect, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const errorHandler = (error: Error, errorInfo: any) => {
      console.error('Error Boundary caught an error', error, errorInfo);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError(error.message as any);
      setHasError(true);
    };

    const handleWindowError = (event: ErrorEvent) => {
      errorHandler(event.error, event);
    };

    window.addEventListener('error', handleWindowError);

    return () => {
      window.removeEventListener('error', handleWindowError);
    };
  }, []);

  if (hasError) {
    return <h1>Something went wrong. {error}</h1>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
