import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% { background-position: -200% 0; }
  50% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
`;

const SkeletonContainer = styled.div`
  background: #1e2034;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 20px;
  gap: 20px;
  overflow: hidden;
`;

const SkeletonBlock = styled.div`
  background: linear-gradient(90deg, #1e2034, #2a3449, #1e2034);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2.5s infinite ease-in-out;
  border-radius: 10px;
`;

const SkeletonIcon = styled(SkeletonBlock)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const SkeletonText = styled(SkeletonBlock)`
  width: 50%;
  height: 20px;
`;

const SkeletonButton = styled(SkeletonBlock)`
  width: 100px;
  height: 40px;
  border-radius: 8px;
`;

const TaskSkeletonLoader: React.FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonIcon />
      <SkeletonText />
      <SkeletonButton />
    </SkeletonContainer>
  );
};

export default TaskSkeletonLoader;
