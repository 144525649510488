import React from 'react';
import styled, { css } from 'styled-components';
import CustomButton from './CustomButton';
import IconBox from './IconBox';
import TitleText from './TitleText';
import { TaskStatus } from '../type';
import { useTonAddress } from '@tonconnect/ui-react';

interface HeaderProps {
  title: string;
  value: string | number;
  buttonText: string;
  buttonState: TaskStatus | null;
  isIcon?: boolean;
  iconSrc?: string;
  onClick?: () => void;
}

const HeaderContainer = styled.div<{ disabled: boolean }>`
  background-color: #1e2034;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  min-height: 60px;
  justify-content: space-between;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #1a2739;
      pointer-events: none;
      opacity: 0.6;
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const TextContainer = styled.div<{ isIcon: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isIcon }) => (isIcon ? 'center' : 'start')};
  flex-grow: 1;
  justify-content: flex-start;
`;

const ValueText = styled.span`
  color: #d7d7d7;
  font-size: 18px;
  margin-top: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const truncateTitle = (title: string) => {
  if (title.length > 24) {
    return title.substring(0, 21) + '...';
  }
  return title;
};

const InfoBox: React.FC<HeaderProps> = ({
  title,
  value,
  buttonText,
  buttonState,
  isIcon = false,
  iconSrc,
  onClick,
}) => {
  const isDisabled =
    buttonState === 'completed' || buttonState === 'pendingApproval';
  const tonAddress = useTonAddress();

  return (
    <HeaderContainer disabled={isDisabled}>
      <ContentWrapper>
        {isIcon && iconSrc ? (
          <IconContainer>
            <IconBox iconSrc={iconSrc} />
          </IconContainer>
        ) : null}
        <TextContainer isIcon={isIcon}>
          <TitleText title={truncateTitle(title)} />
          <ValueText>{value}</ValueText>
        </TextContainer>
        <CustomButton onClick={onClick} state={buttonState} text={buttonText} />
      </ContentWrapper>
    </HeaderContainer>
  );
};

export default InfoBox;
