import React from 'react';
import InfoHeader, { useUserBalanceController } from '../components/InfoHeader';
import SlotMachine from '../components/SlotsMachine';

const Slots = ({
  paymentModalVisible,
  setPaymentModalVisible,
}: {
  paymentModalVisible: boolean;
  setPaymentModalVisible: (b: boolean) => void;
}) => {
  const { refetchBalance } = useUserBalanceController();

  return (
    <div>
      <InfoHeader
        paymentModalVisible={paymentModalVisible}
        setPaymentModalVisible={setPaymentModalVisible}
      />
      <SlotMachine refetchBalance={refetchBalance} />
    </div>
  );
};

export default Slots;
