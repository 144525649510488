import { UseMutationOptions } from '@tanstack/react-query';
import { ICustomErrorResponse } from '../type';
import { mainApiInstance } from '../utils/createApiInstance';
import { useMutationCustom } from '../utils/useMutationCustom';

export type NextStepResponseObject = {
  id: string;
};

export type NextStepRequestDto = {
  task_id: string;
};

export type MoveToNextStepMutationParams = Omit<
  UseMutationOptions<
    NextStepResponseObject,
    ICustomErrorResponse,
    NextStepRequestDto
  >,
  'mutationFn'
>;

const moveToNextStep = async (params: NextStepRequestDto) => {
  return await mainApiInstance.put<NextStepResponseObject>(
    `tasks/move-to-next-step`,
    {
      body: JSON.stringify({
        task_id: params.task_id,
      }),
    }
  );
};

export const useMoveToNextStep = (params: MoveToNextStepMutationParams) => {
  const { mutateAsync, isLoading } = useMutationCustom(
    ['move-to-next-step'],
    moveToNextStep,
    { ...params }
  );

  return { moveToNextStep: mutateAsync, isLoading };
};
