import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';

// TODO: add typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ICustomResponseError = any;

export const useQueryCustom = <TData = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TData, QueryKey>,
  options: Omit<
    UseQueryOptions<TData, ICustomResponseError, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  // const navigate = useNavigate();

  return useQuery<TData, ICustomResponseError>(queryKey, queryFn, {
    ...options,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- we need to call onError from options, but not return it
      options.onError && options.onError(error);

      if (error.status !== 401) {
        toast.error(
          `there was an error in ${queryKey} ${JSON.stringify(error)}`
        );
      }

      if (error.status === 401) {
        console.log('we might have redirect here');
      }
    },
  });
};
